<template>
  <div class="settings-container-fluid">
    <vue-headful
      :title="metadata.title"
      :description="metadata.description"
      :image="metadata.image"
      :url="metadata.url"
      :keywords="metadata.keyword"
      :head="metadata.head"
    />
    <!-- <loader v-if="loading"/> -->
    <div class="container-fluid mb-3">
      <div class="container-fluid bg-block py-3 px-0">
        <div class="settings-container">
          <h2 class="default-title mb-5">
            <i
              class="far fa-cog mr-2 d-none d-md-inline"
              style="font-size: 1.8em;     vertical-align: middle;"
            />
            <span v-t="{path: 'BLOCK.SETTINGS'}"/>
          </h2>
          <div class="container-fluid px-0">
            <span v-if="isActiveFeature('settings_my_preference')">
              <!--My Preferences section-->
              <div class="block-title-container">
                <span
                  v-t="{path: 'BLOCK.BLOCK.MY_PREFERENCE'}"
                  class="block-title-container-span"
                />
              </div>
              <div class="row my-4">
                <label
                  for="name"
                  class="col-12 col-sm-5 col-md-6 font-weight-bold"
                >
                  <span :class="'fa-stack ml-rtl-2 mr-ltr-2'">
                    <i class="far fa-bookmark fa-stack-2x fa-rotate-270"/>
                    <i class="fa-stack-1x settings-icon-text">{{ iso2lang }}</i>
                  </span>
                  <span v-t="{path: 'BLOCK.LANGUAGE'}"/>
                </label>
                <div
                  class="col-12 col-md-6 col-sm-7 ml-5 ml-md-0 ml-sm-0 mw-100 text-rtl-right text-ltr-left"
                >
                  <button
                    v-for="(item, key) in languages"
                    :key="key"
                    class="language-select"
                    :class="{'language-select-active': selected === key}"
                    @click="setSelected(key)"
                  >
                    {{ item }}
                  </button>
                </div>
              </div>

              <div class="row my-4">
                <label
                  for="name"
                  class="col-12 col-md-6 col-sm-5 font-weight-bold text-rtl-right text-ltr-left"
                >
                  <span :class="'fa-stack ml-rtl-2 mr-ltr-2'">
                    <i class="far fa-circle fa-stack-2x"/>
                    <i class="fas fa-headphones fa-stack-1x"/>
                  </span>
                  <span v-t="{path: 'BLOCK.AUDIO_LANGUAGE'}"/>
                </label>
                <div
                  class="col-12 col-md-6 col-sm-7 ml-5 ml-md-0 ml-sm-0 mw-100 text-rtl-right text-ltr-left"
                >
                  <button
                    v-for="(item, key) in languages"
                    :key="key"
                    class="language-select"
                    :class="{'language-select-active': selectedAudio === key}"
                    @click="setAudioSelected(key)"
                  >
                    {{ item }}
                  </button>
                </div>
              </div>
            </span>
            <!--My account section-->
            <template v-if="isFullAuth">
              <div class="block-title-container">
                <span
                  v-t="{path: 'BLOCK.BLOCK.MY_ACCOUNT'}"
                  class="block-title-container-span"
                />
              </div>
              <template v-if="isActiveFeature('settings_account_extra_data')">
                <div class="row my-4">
                  <div
                    :class="'col-12 col-sm-5 col-md-6 font-weight-bold d-flex align-items-center text-rtl-right text-ltr-left'"
                  >
                    <span :class="'fa-stack ml-rtl-2 mr-ltr-2'">
                      <i class="far fa-user settings-icon"/>
                    </span>
                    <span v-t="{path: 'BLOCK.USER.FIRST_NAME'}"/>
                  </div>
                  <div
                    class="col-12 col-md-6 col-sm-7 ml-5 ml-md-0 ml-sm-0 mw-100 d-flex justify-content-end text-my-account text-rtl-right text-ltr-left"
                  >
                    <span>{{ userData.first_name }}</span>
                  </div>
                </div>
                <div class="row my-4">
                  <div
                    :class="'col-12 col-sm-5 col-md-6 font-weight-bold d-flex align-items-center text-rtl-right text-ltr-left'"
                  >
                    <span :class="'fa-stack ml-rtl-2 mr-ltr-2'">
                      <i class="far fa-user settings-icon"/>
                    </span>
                    <span v-t="{path: 'BLOCK.USER.LAST_NAME'}"/>
                  </div>
                  <div
                    class="col-12 col-md-6 col-sm-7 ml-5 ml-md-0 ml-sm-0 mw-100 d-flex justify-content-end text-my-account text-rtl-right text-ltr-left"
                  >
                    <span>{{ userData.last_name }}</span>
                  </div>
                </div>
                <div class="row my-4">
                  <div
                    :class="'col-12 col-sm-5 col-md-6 font-weight-bold d-flex align-items-center text-rtl-right text-ltr-left'"
                  >
                    <div :class="'fa-stack d-inline-block ml-rtl-2 mr-ltr-2'">
                      <img
                        :src="require('src/assets/icons/phone-menu.png')"
                        alt="phone menu icon"
                      >
                    </div>
                    <span v-t="{path: 'BLOCK.USER.PHONE'}"/>
                  </div>
                  <div
                    class="col-12 col-md-6 col-sm-7 ml-5 ml-md-0 ml-sm-0 mw-100 d-flex justify-content-end text-my-account text-rtl-right text-ltr-left"
                  >
                    <span>{{ userData.phone }}</span>
                  </div>
                </div>
              </template>
              <div class="row my-4">
                <div
                  :class="'col-12 col-sm-5 col-md-6 font-weight-bold d-flex align-items-center text-rtl-right text-ltr-left'"
                >
                  <span :class="'fa-stack ml-rtl-2 mr-ltr-2'">
                    <i class="far fa-envelope settings-icon"/>
                  </span>
                  <span v-t="{path: 'BLOCK.USER.EMAIL'}"/>
                </div>
                <div
                  class="col-12 col-md-6 col-sm-7 ml-5 ml-md-0 ml-sm-0 mw-100 d-flex justify-content-end text-my-account text-rtl-right text-ltr-left"
                >
                  <span>{{ userEmail }}</span>
                </div>
              </div>


              <div class="row my-4">
                <div
                  :class="'col-12 col-md-6 col-sm-5 font-weight-bold d-flex align-items-center text-rtl-right text-ltr-left'"
                >
                  <span :class="'fa-stack ml-rtl-2 mr-ltr-2'">
                    <i class="far fa-unlock settings-icon"/>
                  </span>
                  <span v-t="{path: 'BLOCK.USER.PASSWORD.TITLE'}"/>
                </div>
                <div
                  class="col-12 col-md-6 col-sm-7 ml-5 ml-md-0 ml-sm-0 mw-100 d-flex justify-content-end text-rtl-right text-ltr-left"
                >
                  <button
                    class="btn btn-link px-0 btn-my-account text-my-account"
                    @click.stop="showChangePasswordPopup"
                  >
                    <u v-t="{path: 'BLOCK.USER.PASSWORD.CHANGE_PASSWORD'}"/>
                  </button>
                </div>
              </div>
            </template>

            <div v-if="customer === 'nine'" class="row mt-5 subscription">
              <div
                :class="'col-12 col-sm-5 col-md-6 font-weight-bold d-flex align-items-center text-rtl-right text-ltr-left'"
              >
                <span :class="'fa-stack ml-rtl-2 mr-ltr-2'">
                  <img
                    :src="require('src/assets/icons/Subscription - Main.png')"
                    alt="subscription"
                  >
                </span>
                <span class="bold"> My subscriptions</span>
              </div>
            </div>

            <div v-if="customer === 'nine'" class="row my-4">
              <div
                :class="'col-12 col-md-6 col-sm-5 font-weight-bold d-flex align-items-center text-rtl-right text-ltr-left'"
              >
                <span>
                  <img
                    :class="'fa-stack ml-rtl-2 mr-ltr-2'"
                    :src="require('src/assets/icons/crown.png')"
                    alt="crown"
                  >
                </span>
                <span>Go premium</span>
              </div>
              <div
                class="col-12 col-md-6 col-sm-7 ml-5 ml-md-0 ml-sm-0 mw-100 d-flex justify-content-end text-rtl-right text-ltr-left"
              >
                <button
                  class="btn-subscribe"
                  @click.stop="showCouponPurchase"
                >
                  Subscribe
                </button>
              </div>
            </div>

            <template v-if="isFullAuth">
              <div class="row my-4">
                <div
                  :class="'col-12 col-sm-5 col-md-6 font-weight-bold d-flex align-items-center text-rtl-right text-ltr-left'"
                >
                  <span :class="'fa-stack ml-rtl-2 mr-ltr-2'">
                    <i class="far fa-calendar-alt settings-icon"/>
                  </span>
                  <span v-t="{path: 'BLOCK.BLOCK.CONTACT_US.EXPIRATION'}"/>
                </div>
                <div
                  class="col-12 col-md-6 col-sm-7 ml-5 ml-md-0 ml-sm-0 mw-100 d-flex justify-content-end text-my-account text-rtl-right text-ltr-left"
                >
                  <span>{{ expirationTime }}</span>
                </div>
              </div>
              <div class="row my-4 subscription">
                <div
                  :class="'col-12 font-weight-bold d-flex align-items-center text-rtl-right text-ltr-left'"
                >
                  <span :class="'fa-stack ml-rtl-2 mr-ltr-2'">
                    <img
                      :src="require('src/assets/icons/Subscription.png')"
                      alt="subscription"
                    >
                  </span>
                  <div
                    class=" col-12 font-weight-bold d-flex align-items-center text-rtl-right text-ltr-left"
                    style="padding-left: 0;"
                  >
                    <button
                      type="button"
                      class="btn btn-link btn-my-account"
                      style="font-size: 1.2rem; padding-left: 0;"
                      @click="cancelSubscription"
                    >
                      <u>Cancel subscription</u>
                    </button>
                  </div>
                </div>
              </div>
            </template>
            <!--Contact us section-->
            <span v-if="isActiveFeature('settings_contact_us')">
              <div class="block-title-container">
                <span
                  v-t="{path: 'BLOCK.BLOCK.CONTACT_US.TITLE'}"
                  class="block-title-container-span"
                />
              </div>
              <div class="row my-4">
                <div
                  :class="'col-12 col-sm-5 col-md-6 font-weight-bold d-flex align-items-center text-rtl-right text-ltr-left'"
                >
                  <span :class="'fa-stack ml-rtl-2 mr-ltr-2'">
                    <i class="far fa-envelope settings-icon"/>
                  </span>
                  <span v-t="{path: 'BLOCK.BLOCK.CONTACT_US.EMAIL.NAME'}"/>
                </div>
                <div
                  class="col-12 col-md-6 col-sm-7 ml-5 ml-md-0 ml-sm-0 mw-100 d-flex align-items-center text-rtl-right text-ltr-left"
                >
                  <button
                    class="btn btn-link px-0 btn-my-account text-my-account"
                    @click.stop.prevent
                  >
                    <span v-t="{path: 'BLOCK.BLOCK.CONTACT_US.EMAIL.VALUE'}"/>
                  </button>
                </div>
              </div>
              <div class="row my-4">
                <div
                  :class="'col-12 col-sm-5 col-md-6 font-weight-bold d-flex align-items-center text-rtl-right text-ltr-left'"
                >
                  <div :class="'fa-stack d-inline-block ml-rtl-2 mr-ltr-2'">
                    <img
                      :src="require('src/assets/icons/phone-menu.png')"
                      alt="phone menu icon"
                    >
                  </div>
                  <span v-t="{path: 'BLOCK.BLOCK.CONTACT_US.PHONE.NAME'}"/>
                </div>
                <div
                  class="col-12 col-md-6 col-sm-7 ml-5 ml-md-0 ml-sm-0 mw-100 d-flex align-items-center text-my-account text-rtl-right text-ltr-left"
                >
                  <span v-t="{path: 'BLOCK.BLOCK.CONTACT_US.PHONE.VALUE'}"/>
                </div>
              </div>
            </span>
            <span v-if="isActiveFeature('settings_feedback_form')">
              <div class="block-title-container">
                <span
                  v-t="{path: 'BLOCK.BLOCK.CONTACT_US.TITLE'}"
                  class="block-title-container-span"
                />
              </div>
              <div class="register">
                <form
                  name="form"
                  @submit.prevent="submitHandler"
                >
                  <label for="email">
                    <input
                      id="email"
                      v-model="contactUsForm.email"
                      required
                      type="email"
                      name="email"
                      placeholder="Email"
                    >
                  </label>
                  <div
                    v-if="emailError"
                    class="requirements"
                  >
                    Email address is not correct
                  </div>

                  <label for="name">
                    <input
                      id="name"
                      v-model="contactUsForm.name"
                      required
                      type="text"
                      name="name"
                      minlength="2"
                      placeholder="Name"
                    >
                  </label>
                  <div
                    v-if="nameError"
                    class="requirements"
                  >
                    Enter your correct name
                  </div>

                  <label for="subject">
                    <input
                      id="subject"
                      v-model="contactUsForm.subject"
                      required
                      type="text"
                      name="subject"
                      minlength="5"
                      placeholder="Subject"
                    >
                  </label>
                  <div
                    v-if="subjectError"
                    class="requirements"
                  >
                    Enter your message subject
                  </div>

                  <textarea
                    id="message"
                    v-model="contactUsForm.body"
                    required
                    type="text"
                    name="message"
                    minlength="6"
                    placeholder="Message"
                  />
                  <div
                    v-if="bodyError"
                    class="requirements"
                  >
                    Enter your message
                  </div>
                  <div
                    class="form-group auth-form-group auth-privacy d-flex align-items-center"
                    :class="{
                      'justify-content-start': !isActiveFeature('webview_feature'),
                      'justify-content-center': isActiveFeature('webview_feature'),
                    }"
                  />

                  <div class="send">
                    <button type="submit">Send</button>
                  </div>
                  <span class="redirect-terms">
                    <label class="terms">
                      <a
                        v-t="{ path: 'BLOCK.TERMS' }"
                        :href="redirectUrl"
                        target="_blank"
                      />
                    </label>
                  </span>
                </form>
              </div>
            </span>
            <!--Prices section-->
            <span v-if="isActiveFeature('settings_price_info')">
              <div class="block-title-container">
                <span
                  v-t="{path: 'BLOCK.BLOCK.PRICES.TITLE'}"
                  class="block-title-container-span"
                />
              </div>
              <div class="row my-4">
                <div
                  :class="'col-12 col-sm-5 col-md-6 font-weight-bold d-flex align-items-center text-rtl-right text-ltr-left'"
                >
                  <span :class="'fa-stack ml-rtl-2 mr-ltr-2'">
                    <i class="fal fa-credit-card settings-icon"/>
                  </span>
                  <span v-t="{path: 'BLOCK.BLOCK.PRICES.COST.NAME'}"/>
                </div>
                <div
                  class="col-12 col-md-6 col-sm-7 ml-5 ml-md-0 ml-sm-0 mw-100 d-flex align-items-center text-my-account text-rtl-right text-ltr-left"
                >
                  <span v-t="{path: 'BLOCK.BLOCK.PRICES.COST.VALUE'}"/>
                </div>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isFullAuth"
      class="container-fluid position-relative purchases-container"
    >
      <loader
        v-if="loading"
        class="mt-5"
      />
      <div class="container-fluid bg-block px-0 ">
        <pro-carousel
          :area="purchases"
          :items-prop="items"
          class="py-3"
        />
      </div>
    </div>
    <change-password-popup
      v-if="isShowChangePasswordPopup"
      @close-popup="isShowChangePasswordPopup = !isShowChangePasswordPopup"
    />
  </div>
</template>

<script>
  import Loader from 'src/components/loader/loader';
  import Config from 'src/service/config';
  import { localStore } from 'vimmi-web-utils/esm/localStore';
  import { AuthVariableMixin, ScreenReadyMixin } from 'src/plugins/mixin';
  import Auth from 'src/service/authService';
  import { normalizeLang } from 'vimmi-web-utils/cjs/normalizeLang';
  import normaliseMetaTags from 'src/utils/normaliseMetaTags';
  import { amsClient } from 'src/service/ams';
  import ProCarousel from 'src/components/pro-carousel/pro-carousel';
  import { $stats } from '../../plugins/analytics/vue-analytics-plugin';

  export default {
    name: 'SettingsPage',
    components: {
      Loader,
      ChangePasswordPopup: () => import('src/components/change-password/change-password'),
      ProCarousel,
    },
    mixins: [ScreenReadyMixin, AuthVariableMixin],
    data() {
      return {
        customer: process.env.VUE_APP_CUSTOMER,
        expirationTime: null,
        redirectUrl: Config.get('terms_url'),
        emailError: false,
        pattern: /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/,
        nameError: false,
        subjectError: false,
        bodyError: false,
        screenRequestTime: null,
        loading: false,
        selected: this.$i18n.locale,
        selectedAudio: null,
        // template: null,
        languages: Config.get('languages.list'),
        metadata: {},
        userEmail: '',
        userData: {
          first_name: '',
          last_name: '',
          phone: '',
          email: '',
          privacy: false,
        },
        isShowChangePasswordPopup: false,
        purchases: {
          aspect_ratio: '16x9',
          backdrop: null,
          directive: 'more',
          dtype: 'carousel',
          aclass: 'section',
          itype: 'section_filters',
          title: '',
          item_image_shape: 'horizontal',
          item_title_position: 'bottom',
        },
        contactUsForm: {
          email: "",
          name: "",
          subject: "",
          body: "",
        },
        items: [],
        // linkNumber: null,
        // contactNumberLocal: null,
        //        isAuth: false,
      };
    },

    computed: {
      iso2lang() {
        return normalizeLang(this.selected, 'iso-2');
      },
      // link() {
      //   return this.isFullAuth && this.template
      //     ? this.template
      //       .replace(/{{lang}}/gi, this.iso2lang)
      //       .replace(
      //         /{{private_id}}/gi,
      //         encodeURIComponent(Auth.get('user.privateId')),
      //         // ||              'a3cFWhkBHxmDxTiy%2BY6xxiP69WcK5C1QFUgqTxRX0DIZE47NWiOzVZ%2BkFBkHCWS0HlQTLYxWNqIVhd1qqkDc%2B2og%3D%3D',
      //       )
      //     : null;
      // },

      ffbid() {
        return this.isFullAuth
          ? Auth.get('user.second_screen')
            ? Auth.get('ais.idValue')
            : null
          : null;
      },
      // contactnumber() {
      //   return this.isFullAuth
      //     ? Auth.get('user.second_screen')
      //       ? Auth.get('ais.idValue')
      //       : this.contactNumberLocal
      //     : null;
      // },
      // FIXME: Feature Toggle fungus-my-account
      isUserAccount() {
        return this.isActiveFeature('fungus-my-account');
      },
      isUserAccountData() {
        return this.isActiveFeature('fungus-my-account-data');
      },
    },
    watch: {
      $i18n: function () {
        console.log('deb: $i18n is', this.$i18n);
      },
    },
    created() {
      let prevAudio = localStore.get('preferred_audio_lang');
      if (!prevAudio) {
        this.selectedAudio = this.$i18n.locale;
        this.setAudioSelected(this.$i18n.locale);
      } else {
        this.selectedAudio = prevAudio;
      }


      Config.loaded(() => {
        $stats.send('page_opened', {
          page_type: 'settings',
        });
        // this.$logger.log(Config.get('languages'));
        // this.template = Config.get('auth.fungus.vaccount');
        // this.linkNumber = Config.get('auth.fungus.contact_number');
        // this.userEmail = amsClient.get('user.username');
        // console.log(Config.get('auth.fungus'), this.linkNumber);
        this.languages = Config.get('languages.list');
        this.onChangedUserState();
      });

      // this.$bus.$on('changedUserState', this.getUserData);
    },
    updated() {
      this.purchases.title = this.$t('BLOCK.BLOCK.PURCHASES');
    },
    beforeDestroy() {
      // this.$bus.$off('changedUserState', this.getUserData);
    },

    async mounted() {
      let head = await this.getData();
      let data = head && head.seo_metadata ? head.seo_metadata : head;
      this.metadata = normaliseMetaTags(data);
      this.setScreenReady(head, this.screenRequestTime);
      this.getExpirationTime();
    },

    methods: {
      getExpirationTime() {
        let subscriptions = amsClient.get('products');
        if (subscriptions && Object.keys(subscriptions).length) {
          let subcription = subscriptions[Object.keys(subscriptions)[0]];
          this.expirationTime = subcription.expired_at.split(' ')[0].replaceAll('-', '/');
        } else {
          this.expirationTime = null
        }
      },
      cancelSubscription() {
        if (!this.sendRequest) {
          this.sendRequest = true
          amsClient.callAms('/shop/customer/stripe/customer_portal/', { cache: false }).then((result) => {
            if (result.info.status !== 'error') {
              // window.open(result.info.redirect_url)
              let link = document.createElement('a');
              link.href = result.info.redirect_url + '/';
              link.target = '_parent';
              link.classList.add('d-none');
              document.body.appendChild(link);
              link.click();
            } else {
              this.$bus.$emit('toggleAuthPopup', {
                component: 'message',
                forceCloseBtn: true,
                closeOutside: false,
                force: true,
                message: result.info.message,
                actions: {
                  close: this.$i18n.t("OK"),
                },
              });
            }
            this.sendRequest = false
          })
        }
      },
      submitHandler() {
        let isError = false;
        if (this.contactUsForm.name.length < 2) {
          this.nameError = !this.nameError;
          isError = true;
        }
        if (this.contactUsForm.subject.length < 5) {
          this.subjectError = !this.subjectError;
          isError = true;
        }
        if (this.contactUsForm.body.length < 6) {
          this.bodyError = !this.bodyError;
          isError = true
        }

        if (!this.pattern.test(this.contactUsForm.email)) {
          this.emailError = !this.subjectError;
          isError = true;
        }
        if (isError) {
          return false
        } else {
          this.emailError = false
          this.nameError = false
          this.subjectError = false
          this.bodyError = false
          this.postMessage()
        }

      },
      postMessage(e) {
        amsClient.callAms("contact_us", { method: "post", body: JSON.stringify(this.contactUsForm) })
          .then((result) => {
            this.$bus.$emit('toggleAuthPopup', {
              component: 'message',
              forceCloseBtn: true,
              closeOutside: false,
              force: true,
              message: result.head.send_status ? this.$i18n.t("BLOCK.BLOCK.CONTACT_US.SUCCESS_MESSAGE") : this.$i18n.t("BLOCK.BLOCK.CONTACT_US.ERR0R_MESSAGE"),
              actions: {
                close: this.$i18n.t("OK"),
              },
            });
            if (result.head.send_status) {
              this.clearForm();
            }
            console.warn(result)
          }).catch((error) => {
            this.$bus.$emit('toggleAuthPopup', {
              component: 'message',
              forceCloseBtn: true,
              closeOutside: false,
              force: true,
              message: error.message,
              actions: {
                close: this.$i18n.t("OK"),
              },
            });
          })

      },

      clearForm() {
        this.contactUsForm.name = "",
        this.contactUsForm.email = "",
        this.contactUsForm.subject = "",
        this.contactUsForm.body = ""
      },

      onChangedUserState(data = {}) {

        this.getExpirationTime();
        if (this.isFullAuth) {
          this.userEmail = amsClient.get('user.username');
          this.userData.first_name = amsClient.get('user.first_name');
          this.userData.last_name = amsClient.get('user.last_name');
          this.userData.phone = amsClient.get('user.phone');

          this.loading = true;
          amsClient.callAms('/accounts/items/').then(res => {
            this.loading = false;
            this.items = res.items;
          });
        }
      },
      getData() {
        this.screenRequestTime = this.setScreenRequest({
          id: 'set',
          itype: 'screen',
          link: `/screen/set/`,
        });

        return amsClient.callAms(`/screen/set/`, { cache: false })
          .then(data => {
            //            this.$stats.set('current.screen', response.data.head);
            this.setCurrentScreen(data.head);
            data.head.cached = !!data.cached;
            // if (!response.cached) {
            this.setScreenResponse(data.head);
            // }
            return data.head;
          })
          .catch(err => {
            return {
              id: 'error',
              itype: `screen_settings`,
              link: `/screen/set/`,
            };
          });
      },

      setSelected(langIso3) {

        if ((Config.get('languages.rtl') || []).indexOf(langIso3) !== -1) {
          this.activateFeature('rtl');
        } else {
          this.deactivateFeature('rtl');
        }

        this.selected = langIso3;
        this.$i18n.locale = langIso3;
        localStore.set('lang', langIso3); // heb | eng | tha

        Config.set('activeLang', langIso3);

        amsClient.conf({
          appInfo: {
            language: langIso3,
          },
        });

        // this.$stats.updateConfig({
        //   language: langIso3,
        // });

        if (this.items.length) {
          let temp = this.items;
          this.items = [];
          this.$nextTick(() => {
            this.items = temp;

          });
        }
        this.$nextTick(() => {
          if (this.isActiveFeature('rtl')) {
            document.body.classList.add('ft-rtl');
          } else {
            document.body.classList.remove('ft-rtl');
          }
        });


      },

      setAudioSelected(item) {
        this.selectedAudio = item;
        localStore.set('preferred_audio_lang', item);
      },

      // async getUserData() {
      //   // this.isAuth = AuthService.isAuth();
      //   // console.log(
      //   //   `getUserData`,
      //   //   this.isAuth,
      //   //   AuthService.get('fungus.access_token')
      //   // );
      //   if (this.isFullAuth) {
      //     amsClient.callAms('/accounts/items/').then(res => this.items = res.items)
      //   }
      // },

      showChangePasswordPopup() {
        this.isShowChangePasswordPopup = true;
      },
      showCouponPurchase() {
        if (this.isFullAuth) {
          this.$bus.$emit('toggleAuthPopup');

          this.$nextTick(() => {
            this.$bus.$emit('showCouponPurchase', {
              isSettingPage: true,
            });
          });

        } else {
          this.$bus.$emit('toggleAuthPopup', {
            component: 'login',
            force: true,
          });
        }
      },
    },
  };
</script>

<style lang="scss">
  .subscription {
    margin: 0 -15px;

    .icon {
      margin-right: 10px;
    }

    .bold {
      font-size: 22px;
      font-weight: bold;
    }
  }

  .redirect-terms {
    .terms {
      text-decoration: underline;
    }
  }

  .language-select {
    background: transparent;

    border: 0;
    color: $text-color;
    font-size: 1.2em;

    margin: 0 8px;
    outline: none;
    padding: 0;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &:focus,
    &:hover {
      color: $main-color;
      outline: none;
    }

    &-active,
    &:active {
      color: $main-color;
      font-weight: bold;
      text-decoration: none;
    }
  }

  .text-my-account {
    font-size: 1.2em !important;
  }


  .purchases-container {
    transform: translate3d(0, 0, 0);
  }

  .btn-my-account {
    background: transparent;
    border-radius: 4px;
    color: $text-color;
    display: inline-block;
    outline: none;

    &:focus,
    &:hover {
      color: $main-color !important;
      outline: none;
    }

    &-active,
    &:active {
      color: $main-color;
      font-weight: bold;
      outline: none;
    }
  }

  .btn-subscribe {
    background-color: $setting-btn-background-color;
    border: 0;
    border-radius: 7px;
    color: $text-black-color;
    margin-top: 14px;
    outline: none;
    padding: 12px 10px;
    width: 100px;

    &:hover {
      box-shadow: 0 0 5px #fff;
    }
  }

  .settings-icon {
    font-size: 1.8em;
    vertical-align: middle;

    &-text {
      font-size: 0.6em;
      margin-left: -2px;
    }
  }

  .settings-container {
    margin: auto;
    max-width: 600px;
    padding: 0 16px;
    width: 100%;
  }

  .block-title-container {
    margin-bottom: 30px;

    &-span {
      font-size: x-large;
    }
  }

  .ft-rtl {
    .language-select {

      margin: 0 8px;

      &:first-child {
        margin-right: 0;
      }

      &:last-child {
        margin-left: 0;
      }
    }

  }

  .register {
    form {
      label {
        width: 100%;

        input {
          background-color: rgba(255, 255, 255, 0.1);
          border: 0;
          border-radius: 7px;
          color: $settings-text-color;
          outline: none;
          padding: 12px 15px;
          width: 100%;

          &:focus {
            box-shadow: 0 0 5px #00f;
          }

          &::placeholder {
            color: $settings-text-color;
          }

          &:valid {
            background: rgba(255, 255, 255, 0.2);
          }

        }
      }

      .requirements {
        color: $setting-requirements-color;
        padding-left: 15px;
        transition: 300ms;
      }

      textarea {
        background-color: rgba(255, 255, 255, 0.1);
        border: 0;
        border-radius: 7px;
        color: $settings-text-color;
        height: 90px;
        outline: none;
        padding: 12px 15px;
        resize: none;
        width: 100%;

        &::placeholder {
          color: $settings-text-color;
        }

        &:focus {
          box-shadow: 0 0 5px #00f;
        }

        &:valid {
          background: rgba(255, 255, 255, 0.2);
        }
      }

      button {
        background-color: $setting-btn-background-color;
        border: 0;
        border-radius: 7px;
        color: $text-black-color;
        margin-top: 14px;
        outline: none;
        padding: 12px 10px;
        width: 200px;

        &:hover {
          box-shadow: 0 0 5px #fff;
        }
      }

      .terms {
        margin-top: 40px;
      }
    }
  }


</style>
